import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Col,
  Container,
  Image,
  Row,
  Form,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import styles from "./../styles/ConfirmationBooking.module.scss";
import arrowLeft from "./../assets/Profile/arrow-left.svg";
import { formatRupiah, unslugify } from "../constant/lib";
import { AuthServices } from "../http/AuthHttp";
import { BookingServices } from "../http/BookingHttp";
import FlightInformation from "../components/ConfirmationBooking/FlightInformation";
import BusInformation from "../components/ConfirmationBooking/BusInformation";
import HotelInformation from "../components/ConfirmationBooking/HotelInformation";
import BankAccountInformationMobile from "../components/ConfirmationBooking/BankAccountInformationMobile";
import BankAccountInformationDesktop from "../components/ConfirmationBooking/BankAccountInformationDesktop";
import CommonInformationUser from "../components/ConfirmationBooking/CommonInformationUser";
import {
  educationLevel,
  golonganDarah,
  hubunganMahram,
  isAlreadyHajj,
  jenisKelamin,
  occupationList,
  sizeBajuKoko,
  statusBPH,
  statusList,
} from "../constant/variable";
import { iterativeInput, iterativeSelect } from "../common/utils";

export default function ConfirmationBooking() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [itterativeData, setItterativeData] = useState();
  const [dataBook, setDataBook] = useState(null);
  const [edit, setEdit] = useState();
  const total_rent = parseInt(searchParams.get("total_rent"))
    ? parseInt(searchParams.get("total_rent"))
    : 0;

  const guest = parseInt(searchParams.get("guest"))
    ? parseInt(searchParams.get("guest"))
    : 0;
  const price = parseInt(searchParams.get("price"))
    ? parseInt(searchParams.get("price"))
    : 0;
  const airline = searchParams.get("airline")
    ? searchParams.get("airline")
    : "";
  const booking_code = searchParams.get("booking_code")
    ? searchParams.get("booking_code")
    : "";

  // console.log(booking_code);

  const type = searchParams.get("type") ? searchParams.get("type") : "";
  const title = searchParams.get("title") ? searchParams.get("title") : "";
  const departure = searchParams.get("departure")
    ? searchParams.get("departure")
    : "";
  const arrival = searchParams.get("arrival")
    ? searchParams.get("arrival")
    : "";
  const start_date = searchParams.get("start_date")
    ? searchParams.get("start_date")
    : "";
  const end_date = searchParams.get("end_date")
    ? searchParams.get("end_date")
    : "";
  const adults = searchParams.get("adults") ? searchParams.get("adults") : 0;
  const children = searchParams.get("children")
    ? searchParams.get("children")
    : 0;

  useEffect(() => {}, []);

  useEffect(() => {
    let getData = localStorage.getItem("bookFlight");
    if (getData) {
      let tmp = JSON.parse(getData);
      setDataBook(tmp);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    checkStatus();
  }, []);

  // useEffect(() => {
  //   console.log(data, "data");
    
  // }, [data]);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const checkStatus = async () => {
    setIsLoading(true);
    try {
      const response = await BookingServices.getCheckStatus(booking_code);
      if (response) {
        // let clone = { ...response.data };
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleChangeIterative = (e) => {
    let newData = { ...itterativeData };
    if (e.target.name === "fileInput") {
      newData[e.target.id] = e.target.files[0];
    } else {
      newData[e.target.id] = e.target.value;
    }
    setItterativeData(newData);
  };

  const getData = async () => {
    setIsLoading(true);
    let data = localStorage.getItem("riyuz-auth");
    data = data && JSON.parse(data);
    try {
      const response = await AuthServices.getMe(`?id=${data.id}`);

      if (response) {
        // let clone = { ...response.data };
        setIsLoading(false);
        setData(response.user);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const doCheckout = async (e) => {
    e.preventDefault();
    // setIsLoading(true);

    // let data = localStorage.getItem("riyuz-auth");
    // data = data && JSON.parse(data);
    let formPayload = {
      code: booking_code,
      last_name: data?.last_name,
      first_name: data?.first_name,
      email: data?.email,
      phone: data?.phone,
      address_line_1: data?.address ? data?.address : "",
      address_line_2: data?.address2 ? data?.address2 : "",
      city: data?.city,
      country: data?.country,
      state: data?.state ? data?.state : "",
      zip_code: data?.zip_code ? data?.zip_code : "",
      customer_notes: data?.customer_notes ? data?.customer_notes : "",
      term_conditions: 1,
      coupon_code: data?.coupon_code ? data?.coupon_code : "",
      user_id: data?.id,
    };

    // console.log(formPayload, "formPayload", data);
    

    let payload = {};
    if (type === "hotel" || type === "flight") {
      payload = { ...formPayload };
    } else if (type === "tour" || type === "umroh") {
      payload = { ...formPayload, ...itterativeData };
    }
    payload.payment_gateway = "offline_payment";
    const formData = new FormData();

    console.log(payload, "payload", type);
    
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    console.log(payload);
    try {
      const response = await BookingServices.postCheckout(formData);
      if (response) {
        navigate(`/thankyou/${booking_code}`);
        let clone = { ...response.data };
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const total = dataBook?.reduce((acc, item) => {
    const subtotal = parseInt(item.qty) * parseInt(item.price);
    return acc + subtotal;
  }, 0);

  const totalHotelCost = () => {
    let totalPrice = (parseInt(adults) + parseInt(children)) * price;
    return totalPrice;
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <WebLayout
      isLoading={isLoading}
      type="hide"
      title="Confirmation Booking"
      color="dark"
    >
      <Container>
        <Row className={styles.contentWrap}>
          <Col lg={8} md={12} sm={12}>
            <div className="d-block d-md-none">
              <Image
                onClick={handleBack}
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "10px",
                  zIndex: 9999,
                }}
                className="mb-2"
                src={arrowLeft}
                alt="arrow-left"
              />
            </div>
            <div className={styles.formWrap}>
              <CommonInformationUser data={data} handleChange={handleChange} />
              {type === "umroh" && (
                <>
                  <div className={`${styles.heading} mb-3 mt-3`}>
                    Data Jamaah:
                  </div>
                  {/* <Accordion defaultActiveKey="0" flush className="mt-3">
                    {[...Array(guest)].map((item, idx) => {
                      return <>{formAccordion(idx)}</>;
                    })}
                  </Accordion> */}
                  <div className={styles.pilgrimWrap}>
                    {[...Array(guest)].map((item, idx) => {
                      let guestNumber = `passengers[${guest + 1}][`;
                      return (
                        <div className={styles.pilgrimContainer}>
                          <Accordion
                            defaultActiveKey={guestNumber}
                            className={styles.accordionWrap}
                          >
                            <Accordion.Item eventKey={guestNumber}>
                              <Accordion.Header>
                                <div className="fw-bold">Jamaah {idx + 1}</div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className={`${styles.inputForm} _custom`}>
                                  <form
                                    className={styles.accordionWrapChildren}
                                  >
                                    <Accordion defaultActiveKey="0">
                                      <Accordion.Item
                                        eventKey="0"
                                        style={{ border: "none" }}
                                      >
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Informasi Pribadi
                                        </Accordion.Header>

                                        <Accordion.Body className="">
                                          <Row>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Nomor KTP",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][no_ktp]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Nama Lengkap",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][nama_lengkap]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Jenis Kelamin",
                                                `passengers[${
                                                  guest + 1
                                                }][jenis_kelamin]`,
                                                jenisKelamin,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Nama Ayah Kandung",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][nama_ayah_kandung]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Tempat Lahir",
                                                "date",
                                                `passengers[${
                                                  guest + 1
                                                }][tempat_lahir]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Telepon",

                                                "tel",

                                                `passengers[${
                                                  guest + 1
                                                }][telp]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "No Rumah",

                                                "number",

                                                `passengers[${
                                                  guest + 1
                                                }][no_unit]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6}>
                                              {iterativeInput(
                                                "RT",
                                                "number",
                                                `passengers[${guest + 1}][rt]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={6} sm={6} xs={6}>
                                              {iterativeInput(
                                                "RW",
                                                "number",
                                                `passengers[${guest + 1}][rw]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Desa / Kelurahan",

                                                "text",

                                                `passengers[${
                                                  guest + 1
                                                }][desa_kelurahan]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Kecamatan",

                                                "text",

                                                `passengers[${
                                                  guest + 1
                                                }][kecamatan]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Kabupaten",

                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][kabupaten_kodya]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Provinsi",
                                                "text",

                                                `passengers[${
                                                  guest + 1
                                                }][provinsi]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Kode Pos",

                                                "number",

                                                `passengers[${
                                                  guest + 1
                                                }][kode_pos]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12} />
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="1">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Alamat
                                        </Accordion.Header>

                                        <Accordion.Body>
                                          <Row>
                                            <Col lg={12} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <label className={styles.text}>
                                                  Alamat
                                                </label>
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <Form.Control
                                                    className={`${styles.inputBox} ${styles.inputBox_confirmation}`}
                                                    as="textarea"
                                                    onChange={
                                                      handleChangeIterative
                                                    }
                                                    id={`passengers[${
                                                      guest + 1
                                                    }][alamat]`}
                                                    placeholder="Alamat"
                                                    style={{ height: "200px" }}
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <label className={styles.text}>
                                                  Alamat Surat Menyurat
                                                </label>
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <Form.Control
                                                    className={`${styles.inputBox} ${styles.inputBox_confirmation}`}
                                                    as="textarea"
                                                    onChange={
                                                      handleChangeIterative
                                                    }
                                                    id={`passengers[${
                                                      guest + 1
                                                    }][alamat_surat_menyurat]`}
                                                    placeholder="Alamat Surat Menyurat"
                                                    style={{ height: "200px" }}
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="2">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Pendidikan dan Pekerjaan
                                        </Accordion.Header>

                                        <Accordion.Body>
                                          <Row>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Pendidikan",

                                                `passengers[${
                                                  guest + 1
                                                }][pendidikan]`,
                                                educationLevel,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>

                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Pekerjaan",

                                                `passengers[${
                                                  guest + 1
                                                }][pekerjaan]`,

                                                occupationList,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Status Perkawinan",

                                                `passengers[${
                                                  guest + 1
                                                }][status_perkawinan]`,
                                                statusList,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Pergi Haji",

                                                `passengers[${
                                                  guest + 1
                                                }][pergi_haji]`,
                                                isAlreadyHajj,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="3">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Informasi Mahram
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Row>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Nama Mahram",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][nama_mahram]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Hubungan Mahram",
                                                `passengers[${
                                                  guest + 1
                                                }][hubungan_mahram]`,
                                                hubunganMahram,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="4">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Informasi Kesehatan
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Row>
                                            {" "}
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Golongan Darah",
                                                `passengers[${
                                                  guest + 1
                                                }][golongan_darah]`,
                                                golonganDarah,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeSelect(
                                                "Status BPH",
                                                `passengers[${
                                                  guest + 1
                                                }][status_bph]`,
                                                statusBPH,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="5">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Informasi Program
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Row>
                                            <Col
                                              lg={6}
                                              md={12}
                                              sm={12}
                                              className="mb-4"
                                            >
                                              {iterativeInput(
                                                "Program Yang Dipilih",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][program_pilihan]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col
                                              lg={6}
                                              md={12}
                                              sm={12}
                                              className="mb-4"
                                            >
                                              {iterativeInput(
                                                "Tanggal Keberangkatan",
                                                "date",
                                                `passengers[${
                                                  guest + 1
                                                }][tanggal_keberangkatan]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="6">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Ciri - Ciri
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Row>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Rambut",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][rambut]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Alis",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][alis]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Hidung",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][hidung]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <label className={styles.text}>
                                                  Tinggi
                                                </label>
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <InputGroup className="mb-3">
                                                    <Form.Control
                                                      id={`passengers[${
                                                        guest + 1
                                                      }][tinggi]`}
                                                      placeholder="Tinggi"
                                                      onChange={
                                                        handleChangeIterative
                                                      }
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      cm
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <label className={styles.text}>
                                                  Berat
                                                </label>
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <InputGroup className="mb-3">
                                                    <Form.Control
                                                      onChange={
                                                        handleChangeIterative
                                                      }
                                                      id={`passengers[${
                                                        guest + 1
                                                      }][berat]`}
                                                      placeholder="Berat"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                      kg
                                                    </InputGroup.Text>
                                                  </InputGroup>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              {iterativeInput(
                                                "Muka",
                                                "text",
                                                `passengers[${
                                                  guest + 1
                                                }][muka]`,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                            <Col
                                              lg={6}
                                              md={12}
                                              sm={12}
                                              className="mb-4"
                                            >
                                              {iterativeSelect(
                                                "Ukuran Baju Koko",
                                                `passengers[${
                                                  guest + 1
                                                }][ukuran_baju_koko]`,
                                                sizeBajuKoko,
                                                handleChangeIterative,
                                                styles
                                              )}
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="7">
                                        <Accordion.Header className="border-0 _inside-accordion">
                                          Dokumen
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Row>
                                            <Col lg={6} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <Form.Group
                                                    controlId="formFile"
                                                    className="mb-3"
                                                  >
                                                    <Form.Label>
                                                      Foto KTP
                                                    </Form.Label>
                                                    <Form.Control
                                                      onChange={
                                                        handleChangeIterative
                                                      }
                                                      id={`passengers[${
                                                        guest + 1
                                                      }][foto_ktp]`}
                                                      name="fileInput"
                                                      type="file"
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                              <div
                                                className={styles.inputGroup}
                                              >
                                                <div
                                                  className={styles.inputWrap}
                                                >
                                                  <Form.Group
                                                    controlId="formFile"
                                                    className="mb-3"
                                                  >
                                                    <Form.Label>
                                                      Pas Foto 3x4
                                                    </Form.Label>
                                                    <Form.Control
                                                      onChange={
                                                        handleChangeIterative
                                                      }
                                                      id={`passengers[${
                                                        guest + 1
                                                      }][pasfoto]`}
                                                      name="fileInput"
                                                      type="file"
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </form>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              <BankAccountInformationDesktop />
              <BankAccountInformationMobile />
              <div onClick={doCheckout} className={`btnYellow ${styles.btn}`}>
                Konfirmasi Pembelian
              </div>
            </div>
          </Col>
          <Col lg={4} className={styles.summaryPrice}>
            <div className={styles.summaryBox}>
              <div className={styles.title}>Detail Pemesanan</div>
              <Row className={styles.textWrap}>
                {type !== "flight" && (
                  <>
                    <Col lg={6} className="mb-3">
                      {type !== "car" && (
                        <span className="me-2">x{guest ? guest : 1}</span>
                      )}
                      {unslugify(title)}
                    </Col>
                    <Col lg={6} className="mb-3 text-end">
                      {formatRupiah(price)}
                    </Col>
                  </>
                )}
                {type === "hotel" && (
                  <HotelInformation
                    start_date={start_date}
                    end_date={end_date}
                    adults={adults}
                    children={children}
                  />
                )}
                {type === "flight" && (
                  <FlightInformation
                    dataBook={dataBook}
                    airline={airline}
                    arrival={arrival}
                    departure={departure}
                  />
                )}
                {type === "car" && (
                  <BusInformation
                    total_rent={total_rent}
                    start_date={start_date}
                    end_date={end_date}
                    departure={departure}
                    arrival={arrival}
                  />
                )}
              </Row>
              <div className={styles.totalWrap}>
                <div className={styles.heading}>Total:</div>
                <div className={styles.total}>
                  {type === "flight"
                    ? formatRupiah(total)
                    : type === "hotel"
                    ? formatRupiah(totalHotelCost())
                    : formatRupiah(price)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </WebLayout>
  );
}
