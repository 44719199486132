import React from "react";
import { formatRupiah } from "../../constant/lib";

export default function NonPreviewTotal(props) {
  const { styles, handleSubmit, payload } = props;
  return (
    <div className={styles.stickyAction}>
      <div className={styles.previewPrice}>
        <div className={styles.text}>
          Total:
          {payload.discount && (
            <>
              <span className={styles.subtotalText}>
                {formatRupiah(payload.sub_total)}
              </span>
              {"  "}
              <span className={styles.discountText}>{payload.discount}%</span>
            </>
          )}
        </div>
        <div className={styles.totalText}>{formatRupiah(payload.total)}</div>
      </div>
      <button onClick={handleSubmit} className={`${styles.btnContinue}`}>
        Preview
      </button>
    </div>
  );
}
