import React from "react";

export default function AddItinerary(props) {
  const { addItinerary, styles } = props;
  return (
    <div className="w-100 mb-1 mt-4">
      <div>
        <button className={styles.btnAction} onClick={addItinerary}>
          Add Itenarary
        </button>
      </div>
    </div>
  );
}
