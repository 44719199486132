import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import WebLayout from "../../components/Layout/WebLayout";
import styles from "./../../styles/ProfileMobile.module.scss";
import personalCard from "./../../assets/Profile/personalcard.svg";
import receipt from "./../../assets/Profile/receipt.svg";
import historyMobileActive from "./../../assets/Home/historyMobileActive.svg";
import card from "./../../assets/Profile/card.svg";
import convertCard from "./../../assets/Profile/convert-card.svg";
import briefcase from "./../../assets/Profile/briefcase.svg";
import Menu from "../../components/Profile/ProfileMobile/Menu";
import InformationUser from "../../components/Profile/ProfileMobile/InformationUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PrivateInformation from "../../components/Profile/PrivateInformation";
import { AuthServices } from "../../http/AuthHttp";
import { UserServices } from "../../http/UserHttp";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import { isMobileCheck } from "../../utils";
import InformationUmroh from "../../components/Profile/InformationUmroh";
import HistoryTrx from "../../components/Profile/HistoryTrx";
import BankAccountList from "../../components/Profile/BankAccountList";
import { JamaahServices } from "../../http/JamaahHttp";
import { ListKloterMobile } from "../../components/Profile/ProfileMobile/ListKloterMobile";
import { TitleMenu } from "../../components/Profile/ProfileMobile/TitleMenu";
import ChangePassword from "../../components/Profile/ChangePassword";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { groupedTableStructure } from "../../constant/variable";
import PrimaryInformation from "../../components/Profile/ProfileMobile/PrimaryInformation";
import { PersonalInformation } from "../../components/Profile/ProfileMobile/PersonalInformation";
import { PhysicalInformation } from "../../components/Profile/ProfileMobile/PhysicalInformation";
import { DocumentInformation } from "../../components/Profile/ProfileMobile/DocumentInformation";
import EquipmentInformation from "../../components/Profile/ProfileMobile/EquipmentInformation";
import TravelInformation from "../../components/Profile/ProfileMobile/TravelInformation";
import FinanceInformation from "../../components/Profile/ProfileMobile/FinanceInformation";
import PassportInformation from "../../components/Profile/ProfileMobile/PassportInformation";
import VisaInformation from "../../components/Profile/ProfileMobile/VisaInformation";
import ModalCenter from "../../components/Layout/Modal";

export default function ProfileMobile() {
  const imgDate =
    "https://icons.veryicon.com/png/o/miscellaneous/esgcc-basic-icon-library/date-71.png";
  const departureDate =
    "https://cdn.icon-icons.com/icons2/1369/PNG/512/-flight-takeoff_90329.png";
  const arrivalDate =
    "https://cdn.icon-icons.com/icons2/3260/PNG/512/flight_land_airplane_plane_icon_206577.png";
  const navigate = useNavigate();
  const params = useLocation();
  const currUrl = params.search.split("=")[1];
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [batchList, setBatchList] = useState("");
  const [batchDetail, setBatchDetail] = useState("");
  const [detailForm, setDetailForm] = useState(false);
  const [msgModal, setMsgModal] = useState(false);
  const [umrohBatchId, setUmrohBatchId] = useState();
  const [isCheckPhoneNumber, setIsCheckPhoneNumber] = useState(true);
  const [addFamily, setAddFamily] = useState(false);
  const [isLoadingChecked, setIsLoadingChecked] = useState(false);
  const [formWizardStep, setFormWizardStep] = useState("2");
  const [isFoundUser, setIsFoundUser] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [nohp, setNohp] = useState("");
  const [familyData, setFamilyData] = useState([]);
  const [payloadDataCreateUser, setPayloadDataCreateUser] =
    useState(defaultData);

  useEffect(() => {
    if (localStorage.getItem("riyuz-auth")) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (data) getFamily(`?id=${data.id}`);
  }, [data]);

  // useEffect(() => {
  //   if (data) {

  //   }
  // }, [data]);

  useEffect(() => {
    if (
      currUrl === "my-packages" ||
      currUrl === "my-equipment" ||
      currUrl === "my-retail" ||
      currUrl === "my-trip"
    ) {
      setOpenMenu(true);
      // setDetailForm(true);
      if (data) {
        getPilgrimData("batch", `?user_id=${data?.id}`);
      }
    }
  }, [currUrl, data]);

  useEffect(() => {
    if (currUrl === "history") getHistoryBookData();
  }, [currUrl]);

  const handleChangeFamilyData = (e) => {
    const { id, value, type } = e.target;
    let newData = { ...payloadDataCreateUser };
    if (type === "file") {
      newData[id] = e.target.files[0];
    } else {
      newData[id] = value;
    }
    setPayloadDataCreateUser(newData);
  };

  const [modalShow, setModalShow] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleSubmitData = async () => {
    const formData = new FormData();
    setSaveLoading(true);
    Object.keys(payloadDataCreateUser).forEach((key) => {
      formData.append(key, payloadDataCreateUser[key]);
    });
    formData.append("phone", payloadDataCreateUser.telp);
    if (isFoundUser || !isNewUser) {
      try {
        const response = await JamaahServices.putJamaah(formData);
        if (response.success) {
          setMsgModal(true);
          setAddFamily(false);
          setIsNewUser(false);
          setFamilyData([]);
          getFamily(`?id=${data.id}`);
        } else {
          setMsgModal(false);
        }
        console.log(response);
      } catch (err) {
        setMsgModal(false);
      } finally {
        setSaveLoading(false);
        setModalShow(true);
      }
    } else {
      try {

        const response = await JamaahServices.postJamaah(formData);
        if (response.success) {
          setMsgModal(true);
          setAddFamily(false);
          setFamilyData([]);
          getFamily(`?id=${data.id}`);
        } else {
          setMsgModal(false);
        }
        console.log(response);
      } catch (err) {
        setMsgModal(false);
      } finally {
        setModalShow(true);
        setSaveLoading(false);
      }
    }
  };

  const getHistoryBookData = async () => {
    setIsLoading(true);
    try {
      const response = await UserServices.getHistoryBook();
      if (response) {
        setIsLoading(false);
        setDataProfile(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleNavigationMenu = (url) => {
    navigate(url);
    setOpenMenu(true);
  };

  const getData = async () => {
    setIsLoading(true);
    let data = localStorage.getItem("riyuz-auth");
    data = data && JSON.parse(data);
    try {
      const response = await AuthServices.getMe(`?id=${data.id}`);
      if (response) {
        setIsLoading(false);
        setPayloadDataCreateUser({
          ...payloadDataCreateUser,
          user_login_id: response.user.id,
          user_id: response.user.id,
        });
        setData(response.user);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getFamily = async (param) => {
    setIsLoading(true);

    try {
      const response = await JamaahServices.getFamily(param);
      if (response) {
        setFamilyData(response.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/profile");
    setOpenMenu(false);
    setDetailForm(false);
    setIsCheckPhoneNumber(true);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("riyuz-auth");
    navigate("/");
    setOpenMenu(false);
  };

  const getPilgrimData = async (type, param) => {
    setIsLoading(true);
    try {
      const response = await JamaahServices.getListJamaah(param);
      if (response) {
        setIsLoading(false);
        if (type === "batch") {
          // setBatchList([
          //   {
          //     umroh_batch_id: {
          //       id: 1,
          //       name: "Kloter 1",
          //     },
          //     booking_code: "1234567890",
          //   },
          // ]);
          setBatchList(response.data?.data);
        } else {
          if (response.data?.data?.length > 0) {
            setBatchDetail(response.data?.data[0]);
          }
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setFormWizardStep("2");
  };

  const handleCheckPhoneNumber = async () => {
    setIsLoadingChecked(true);
    try {
      const response = await UserServices.checkPhoneNumber(nohp);
      if (response) {
        setIsCheckPhoneNumber(false);
        setIsLoadingChecked(false);
        if (response.success) {
          let data = response.message;
          setPayloadDataCreateUser(data);
          setIsFoundUser(true);
        } else {
          // setPayloadDataCreateUser(defaultData);
          setIsFoundUser(false);
          setIsNewUser(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WebLayout type="hide-header">
      {!openMenu && (
        <>
          <InformationUser data={data} handleLogout={handleLogout} />
          <Container>
            <Row>
              <Col lg={12}>
                <div className={styles.menuTitle}>Informasi Saya</div>
              </Col>
              <Col lg={12}>
                <Menu
                  isLast={false}
                  link={() => handleNavigationMenu("/profile?menu=personal")}
                  text="Informasi Pribadi"
                  img={personalCard}
                  isAvailable={true}
                />
                <Menu
                  isLast={false}
                  link={() =>
                    handleNavigationMenu("/profile?menu=form-anggota-keluarga")
                  }
                  text="Anggota Keluarga"
                  img={personalCard}
                  isAvailable={true}
                />
                <Menu
                  isLast={false}
                  link={() =>
                    handleNavigationMenu("/profile?menu=my-equipment")
                  }
                  text="My Equipment"
                  img={briefcase}
                  isAvailable={true}
                />{" "}
                <Menu
                  isLast={false}
                  link={() => handleNavigationMenu("/profile?menu=my-packages")}
                  text="My Packages"
                  img={historyMobileActive}
                  isAvailable={false}
                />{" "}
                <Menu
                  isLast={false}
                  link={() => handleNavigationMenu("/profile?menu=my-retail")}
                  text="My Retail"
                  img={convertCard}
                  isAvailable={false}
                />
                <div className={styles.menuTitle}>Lainnya</div>
                <Menu
                  isLast={false}
                  link={() => handleNavigationMenu("/profile?menu=history")}
                  text="Riwayat Transaksi"
                  img={card}
                  isAvailable={true}
                />
                <Menu
                  isLast={false}
                  link={() =>
                    handleNavigationMenu("/profile?menu=change-password")
                  }
                  text="Ubah Password"
                  img={card}
                  isAvailable={true}
                />
                {/* <Menu
                  isLast={false}
                  link={() => handleNavigationMenu("/profile?menu=bank")}
                  text="Akun Bank"
                  img={card}
                  isAvailable={true}
                /> */}
                {/* <Menu isLast={true} isAvailable={true} link={handleLogout} text="Logout" img="" /> */}
              </Col>
            </Row>
          </Container>
        </>
      )}
      {openMenu && (
        <Container className="mt-4" style={{ marginBottom: "6rem" }}>
          <Row>
            <Col lg={12}>
              <Image
                onClick={
                  currUrl === "form-anggota-keluarga" && addFamily
                    ? () => setAddFamily(false)
                    : handleBack
                }
                className="mb-2"
                style={{ cursor: "pointer" }}
                src={arrowLeft}
                alt="arrow-left"
              />
              {currUrl === "personal" && (
                <PrivateInformation
                  isMobile={isMobileCheck()}
                  data={data}
                  setIsLoading={setIsLoading}
                />
              )}
              {currUrl === "my-packages" && (
                <>
                  {detailForm ? (
                    batchDetail &&
                    umrohBatchId && (
                      <InformationUmroh
                        isMobile={isMobileCheck()}
                        data={batchDetail}
                        typeMenu="my-packages"
                        setIsLoading={setIsLoading}
                        getData={() =>
                          getPilgrimData(
                            "detail",
                            `?user_id=${data.id}&umroh_batch_id=${umrohBatchId}`
                          )
                        }
                      />
                    )
                  ) : (
                    <Col lg={8}>
                      <ListKloterMobile
                        data={batchList}
                        getPilgrimData={getPilgrimData}
                        setUmrohBatchId={setUmrohBatchId}
                        setDetailForm={setDetailForm}
                        styles={styles}
                      />
                    </Col>
                  )}
                </>
              )}
              {currUrl === "my-equipment" && (
                <>
                  {detailForm ? (
                    batchDetail &&
                    umrohBatchId && (
                      <InformationUmroh
                        isMobile={isMobileCheck()}
                        data={batchDetail}
                        typeMenu="equipment"
                        setIsLoading={setIsLoading}
                        getData={() =>
                          getPilgrimData(
                            "detail",
                            `?user_id=${data.id}&umroh_batch_id=${umrohBatchId}`
                          )
                        }
                      />
                    )
                  ) : (
                    <Col xs={12} lg={12}>
                      <ListKloterMobile
                        data={batchList}
                        getPilgrimData={getPilgrimData}
                        setUmrohBatchId={setUmrohBatchId}
                        setDetailForm={setDetailForm}
                        styles={styles}
                      />
                    </Col>
                  )}
                </>
              )}
              {currUrl === "my-retail" && (
                <>
                  {detailForm ? (
                    batchDetail &&
                    umrohBatchId && (
                      <InformationUmroh
                        isMobile={isMobileCheck()}
                        data={batchDetail}
                        setIsLoading={setIsLoading}
                        typeMenu="retail"
                        getData={() =>
                          getPilgrimData(
                            "detail",
                            `?user_id=${data.id}&umroh_batch_id=${umrohBatchId}`
                          )
                        }
                      />
                    )
                  ) : (
                    <Col xs={12} lg={12}>
                      <ListKloterMobile
                        data={batchList}
                        getPilgrimData={getPilgrimData}
                        setUmrohBatchId={setUmrohBatchId}
                        setDetailForm={setDetailForm}
                        styles={styles}
                      />
                    </Col>
                  )}
                </>
              )}
              {currUrl === "form-anggota-keluarga" && (
                <>
                  <TitleMenu title="Anggota Keluarga" styles={styles} />
                  {!addFamily && (
                    <>
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => setAddFamily(true)}
                          className={`${styles.btnSubmit} mt-0 py-2 px-3 mb-3`}
                        >
                          Tambah
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nama</th>
                              <th>Hubungan</th>
                              <th>No Hp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <tr>
                                <td colSpan={3} className="text-center">
                                  <Spinner animation="border" size="sm" />
                                </td>
                              </tr>
                            ) : familyData?.length > 0 ? (
                              familyData?.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{item.user.name}</td>
                                    <td>{item.relationship_type}</td>
                                    <td>{item.phone ? item.phone : "-"}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center">
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {addFamily && (
                    <>
                      <div className="d-flex justify-content-end"></div>
                      {isCheckPhoneNumber ? (
                        <>
                          <div className={`${styles.formWizardWrap} mt-4`}>
                            <b>Nomor Handphone</b>
                            <div className={`${styles.inputGroup} mt-1`}>
                              <input
                                type="text"
                                className={`${styles.inputBox} w-100`}
                                placeholder="Masukkan Nomor Handphone"
                                onChange={(e) => setNohp(e.target.value)}
                              />
                            </div>
                          </div>
                          <button
                            disabled={nohp === "" ? true : false}
                            className={`${styles.btnSubmit} d-flex justify-content-center align-items-center mt-4 w-100`}
                            onClick={handleCheckPhoneNumber}
                          >
                            {isLoadingChecked ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="xs"
                                className="me-2"
                              ></Spinner>
                            ) : (
                              ""
                            )}

                            {isLoadingChecked
                              ? "Mengecek Nomor Handphone"
                              : "Cek Nomor Handphone"}
                          </button>
                          <button
                            onClick={() => setAddFamily(false)}
                            className={`${styles.btnSubmit} mt-3 w-100`}
                          >
                            Kembali Ke List
                          </button>
                        </>
                      ) : (
                        <>
                          <div className={styles.formWizardWrap}>
                            {/* <PrimaryInformation
        styles={styles}
        setFormWizardStep={setFormWizardStep}
        formWizardStep={formWizardStep}
        handleChangeFamilyData={handleChangeFamilyData}
        payloadDataCreateUser={payloadDataCreateUser}
      /> */}
                            <PersonalInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            <PhysicalInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            <DocumentInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            <EquipmentInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            <PassportInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            <VisaInformation
                              styles={styles}
                              setFormWizardStep={setFormWizardStep}
                              formWizardStep={formWizardStep}
                              handleChangeFamilyData={handleChangeFamilyData}
                              payloadDataCreateUser={payloadDataCreateUser}
                            />
                            {/* <TravelInformation
        styles={styles}
        setFormWizardStep={setFormWizardStep}
        formWizardStep={formWizardStep}
        handleChangeFamilyData={handleChangeFamilyData}
      /> */}
                            {/* <FinanceInformation
        styles={styles}
        setFormWizardStep={setFormWizardStep}
        formWizardStep={formWizardStep}
        handleChangeFamilyData={handleChangeFamilyData}
      /> */}
                            {/* {groupedTableStructure.map((item, index) => (
        <div key={index} className={styles.collapsibleHeader}>
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() =>
              setFormWizardStep(
                formWizardStep === item.id ? "" : item.id
              )
            }
          >
            <b>{item.group}</b>
            {formWizardStep === item.id ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
          {formWizardStep === item.id && (
            <div className={styles.collapsibleContent}>
              {item.fields.map((field, index) => (
                <div key={index} className="mb-3">
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    className="mb-1"
                  >
                    {field.title}
                  </div>
                  {(() => {
                    switch (field.type_field) {
                      case "text":
                        return (
                          <input
                            type="text"
                            name={field.name}
                            className="w-100"
                          />
                        );
                      case "date":
                        return (
                          <input
                            type="date"
                            name={field.name}
                            className="w-100"
                          />
                        );
                      case "boolean":
                        return (
                          <input
                            type="checkbox"
                            name={field.name}
                            className="w-100"
                          />
                        );
                      case "number":
                        return (
                          <input
                            type="number"
                            name={field.name}
                            className="w-100"
                          />
                        );
                      case "file":
                        return (
                          <input
                            type="file"
                            name={field.name}
                            className="w-100"
                          />
                        );
                      case "textarea":
                        return (
                          <textarea
                            name={field.name}
                            className="w-100"
                          ></textarea>
                        );

                      case "select":
                        return (
                          <select
                            name={field.name}
                            className="w-100"
                          >
                          </select>
                        );
                      default:
                        return "";
                    }
                  })()}
                </div>
              ))}
            </div>
          )}
        </div>
      ))} */}
                          </div>
                          <button
                            className={`${styles.btnSubmit} d-flex justify-content-center align-items-center mt-4 w-100`}
                            onClick={handleSubmitData}
                          >
                            {saveLoading && (
                              <span>
                                <Spinner
                                  animation="border"
                                  role="status"
                                  size="xs"
                                  className="me-2"
                                ></Spinner>
                              </span>
                            )}
                            Save Information
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {currUrl === "history"  && (
                <HistoryTrx
                  isMobile={isMobileCheck()}
                  data={dataProfile}
                  isLoading={isLoading}
                />
              )}
              {currUrl === "bank" && (
                <BankAccountList isMobile={isMobileCheck()} data={data} />
              )}
              {currUrl === "change-password" && (
                <ChangePassword setIsLoading={setIsLoading} />
              )}
            </Col>
          </Row>
        </Container>
      )}
      <ModalCenter
        type="response"
        callback=""
        setShow={modalShow}
        setHide={() => setModalShow(false)}
        setTitle=""
        setBody={`${
          msgModal ? "Berhasil Menambahkan Jamaah" : "Gagal Menambahkan Jamaah"
        }`}
      />
    </WebLayout>
  );
}
const defaultData = {
  // id: 329,
  user_login_id: "",
  user_id: "",
  group_id: "",
  umroh_batch_id: "",
  booking_id: "",
  corporate_id: "",
  leader_id: "",
  member_claim_id: "",
  member_relation: "",
  passport_berlaku: "",
  pergi_umroh: "",
  plakat: "",
  ukuran_baju: "",
  pic_id: "",
  booking_code: "",
  room_type: "",
  room_mate: "",
  no_ktp: "",
  no_passport: "",
  nama_lengkap: "",
  nama_ayah_kandung: "",
  tempat_lahir: "",
  relationship_type: "",
  tanggal_lahir: "",
  jenis_kelamin: "",
  alamat: "",
  telp: "",
  no_unit: "",
  rt: "",
  rw: "",
  alamat_surat_menyurat: "",
  desa_kelurahan: "",
  kecamatan: "",
  kabupaten_kodya: "",
  provinsi: "",
  kode_pos: "",
  pendidikan: "",
  pekerjaan: "",
  status_perkawinan: "",
  pergi_haji: 0,
  nama_mahram: "",
  hubungan_mahram: "",
  golongan_darah: "",
  status_bph: "",
  program_pilihan: "",
  tanggal_keberangkatan: "",
  rambut: "",
  alis: "",
  hidung: "",
  tinggi: "",
  berat: "",
  muka: "",
  ukuran_baju_koko: "",
  foto_ktp: "",
  pasfoto: "",
  is_mahram: 0,
  roommate_relation: "",
  buku_nikah: "",
  kartu_keluarga_1: "",
  kartu_keluarga_2: "",
  ttd_digital: "",
  parent_id: "",
  create_user: "",
  update_user: "",
  status: "",
  briefcase: 0,
  equipment: 0,
  equipment_remark: "",
  shipment: 0,
  shipment_remark: "",
  shipment_receipt: "",
  shipment_delivery_receipt: "",
  add_notes: "",
  food_request: "",
  bus: "",
  ket_promo: "",
  nama_sesuai_passport: "",
  dokumen_passport: "",
  is_fisik_passport: 0,
  baggage_number: "",
  flight_id: "",
  visa_id: "",
  additional_discount: "",
  additional_discount_remark: "",
  additional_equipment_remark: "",
  additional_equipment_price: "",
  additional_addon_remark: "",
  additional_addon_price: "",
  // package_price: 29000000,
  // total_addon_price: "",
  // cashback: "",
  // total_price: "",
  // total_corporate_bill: "",
  // total_bill: "",
  // total_paid: "",
  // remaining_payment: "",
  // refunded: "",
  // refund_remark: "",
  // umroh_pilgrim_addons: [],
};
