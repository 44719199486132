import { Button, Form, Image } from "react-bootstrap";
import styles from "../../../styles/Search.module.scss";
import star from "./../../../assets/Home/star.svg";
import vector from "./../../../assets/Vector.svg";
import { isMobileCheck } from "../../../utils";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
export default function TourSearch(props) {
  const {
    handleSelectLocation,
    handleSelectedLocation,
    locationList,
    handleChange,
    handleFilterByPrice,
    filterData,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;

  const location = useLocation();
  const [start, setStart] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    // Handle cat_id[]
    const catIds = queryParams.getAll("cat_id[]");
    catIds.forEach((catId) => {
      const checkbox = document.querySelector(
        `input[name="cat_id[]"][value="${catId}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    // Handle review_score[]
    const reviewScores = queryParams.getAll("review_score[]");
    reviewScores.forEach((score) => {
      const checkbox = document.querySelector(
        `input[name="review_score[]"][value="${score}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    // Handle attrs[1][]
    const attrs1 = queryParams.getAll("attrs[1][]");
    attrs1.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[1][]"][value="${attr}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    // Handle attrs[2][]
    const attrs2 = queryParams.getAll("attrs[2][]");
    attrs2.forEach((attr) => {
      const checkbox = document.querySelector(
        `input[name="attrs[2][]"][value="${attr}"]`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    // Handle start and end dates
    // const startDate = queryParams.get("start");
    // const endDate = queryParams.get("end");
    // if (startDate && endDate) {
    //   setStartDate(new Date(startDate));
    //   setEndDate(new Date(endDate));
    // }
  }, [location.search]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={styles.filterBox}>
      <Form>
        {/* <Form.Group className="mb-4">
                      <Form.Label>Penerbangan</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="1">Garuda Indonesia</option>
                        <option value="2">Lion Air</option>
                      </Form.Select>
                    </Form.Group> */}
        {/* <Form.Group>
          <Form.Label>From - To</Form.Label>
          <div className={styles.priceGroup}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        </Form.Group> */}
        {/* <Form.Group className="mb-4">
          <Form.Label>Lokasi Keberangkatan</Form.Label>
          <Form.Select
            onClick={handleSelectLocation}
            aria-label="Default select example"
            onChange={handleSelectedLocation}
          >
            <option hidden>-- Pilih Lokasi --</option>
            {locationList?.length > 0 &&
              locationList.map((item) => {
                return <option value={item.id}>{item.title}</option>;
              })}
          </Form.Select>
        </Form.Group> */}
        {/* <Form.Group className="mb-4">
                      <Form.Group className="mb-4"></Form.Group>
                      <Form.Label>Tanggal Keberangkatan</Form.Label>
                      <Form.Control
                        onChange={(e) => handleChange(e, "date")}
                        id="min_price"
                        className={styles.inputDate}
                        type="date"
                        // value={minPrice}
                      />

                      <Form.Control
                        onChange={(e) => handleChange(e, "date")}
                        id="max_price"
                        className={styles.inputDate}
                        type="date"
                        // value={maxPrice}
                      />
                    </Form.Group> */}
        <Form.Group className={styles.formSearch}>
          <div className={`${styles.fieldMain} d-flex mb-4`}>
            <div className={styles.fieldLeft}>
              <Form.Label>From</Form.Label>
              <input
                type="date"
                name="start"
                id="start"
                // value={start}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.fieldRight}>
              <Form.Label>To</Form.Label>
              <input
                type="date"
                name="end"
                id="end"
                // value={end}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </Form.Group>
        <div className={`${styles.fieldMain} mb-1`}>
          <Form.Label>Embarkasi</Form.Label>
          <div>
            <select
              onChange={(e) => handleChange(e)}
              className={`${styles.select} w-100`}
              name="location_id"
              id="location_id"
            >
              <option value="" hidden>
                Pilih Lokasi Embarkasi
              </option>
              {locationList?.length > 0 ? (
                locationList.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
        </div>
        <Form.Group className="mb-4">
          <Form.Group className="mb-4"></Form.Group>
          <Form.Label>Harga</Form.Label>
          <div className={styles.priceGroup}>
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="min_price"
              name="min_price"
              className="mb-0"
              type="text"
              // value={minPrice}
              placeholder="MIN"
            />
            -
            <Form.Control
              onChange={(e) => handleChange(e)}
              id="max_price"
              name="max_price"
              type="text"
              placeholder="MAX"
              className="mb-0"
              // value={maxPrice}
            />
          </div>
          {/* <Button
            onClick={handleFilterByPrice}
            className="btnYellow mt-3 mt-md-4 w-100"
          >
            <Image src={vector} />
            Apply Harga
          </Button> */}
        </Form.Group>
        <hr class="my-3 my-md-3"></hr>
        <Form.Label>Tipe Tour</Form.Label>
        {filterData ? (
          filterData[2].data?.map((item, idx) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  type="checkbox"
                  name="cat_id[]"
                  id="cat_id[]"
                  value={item.id}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })
        ) : (
          <Skeleton height={15} count={5} style={{ marginBottom: "8px" }} />
        )}
        <Form.Group className="mb-4"></Form.Group>

        <Form.Label className="mb-2">Rating</Form.Label>
        {[...Array(5)].map((item, index) => {
          return (
            <div className={styles.starGroup}>
              <input
                type="checkbox"
                onChange={(e) => handleChange(e)}
                aria-label="Checkbox for following text input"
                name="review_score[]"
                id="review_score[]"
                value={5 - index}
              ></input>
              <div className={styles.star}>
                {[...Array(5 - index)].map(() => {
                  return <Image src={star}></Image>;
                })}
              </div>
            </div>
          );
        })}
        <Form.Group className="mb-4"></Form.Group>

        <Form.Label className="mb-2">Style Travel</Form.Label>
        {filterData?.length > 0 && filterData ? (
          filterData[3].data &&
          filterData[3].data[0].terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  type="checkbox"
                  name="attrs[1][]"
                  id="attrs[1][]"
                  value={item.name}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })
        ) : (
          <Skeleton height={15} count={5} style={{ marginBottom: "8px" }} />
        )}
        <Form.Group className="mb-4"></Form.Group>

        <Form.Label className="mb-2">Fasilitas</Form.Label>
        {filterData?.length > 0 && filterData ? (
          filterData[3].data &&
          filterData[3].data[1].terms?.map((item, index) => {
            return (
              <div className={styles.starGroup}>
                <input
                  onChange={(e) => handleChange(e)}
                  type="checkbox"
                  name="attrs[2][]"
                  id="attrs[2][]"
                  value={item.name}
                  aria-label="Checkbox for following text input"
                ></input>
                <div className={styles.star}>{item.name}</div>
              </div>
            );
          })
        ) : (
          <Skeleton height={15} count={5} style={{ marginBottom: "8px" }} />
        )}
      </Form>
      {/* {isMobileCheck() ? (
        ""
      ) : (
        <>
          <hr class="mb-5 mt-5"></hr>
          <div className={styles.customPaket}>
            <div>Tidak Menemukan Paket yang Sesuai?</div>
            <div className={styles.btnCustom}>Custom Paket</div>
          </div>
        </>
      )} */}
    </div>
  );
}
