import React from "react";
import trash from "./../../assets/trash.svg";

export default function ItineraryType(props) {
  const {
    itineraryType,
    styles,
    repetitiveInput,
    handleChange,
    deleteAkomodasi,
    deleteAgenda,
    addAgenda,
    handleChangeAgenda,
    selectedStartDate,
    selectedEndDate,
    handleSubmit,
    addItinerary,
    locationList,
    listFlight,
    listBus,
    listTrain,
    listHotel,
    rooms,
    selectedHotel,
    payload,
  } = props;
  return (
    <>
      {itineraryType?.length > 0 && (
        <div className={`${styles.titleCalculator} mb-1`}>Itinerary</div>
      )}
      {itineraryType.map((elm, idx) => {
        return (
          <div className={styles.wrapIti}>
            <div className={styles.orderIti}>
              <div className="d-flex flex-column align-items-center">
                <div className={styles.idxIti}>{idx + 1}</div>
                <img
                  width={18}
                  height={18}
                  src={trash}
                  onClick={(e) => deleteAkomodasi(e, idx)}
                />
                {/* <button
                          className={styles.btnAction_reset}
                          
                        >
                          <img>
                        </button> */}
              </div>
            </div>
            <div className={styles.formField}>
              {repetitiveInput(
                "select",
                "",
                "Akomodasi",
                typeData,
                (e) => handleChange(e, idx, "accomodation"),
                `object_model`,
                elm.object_model
              )}
              {elm.object_model.includes("flight") && (
                <div>
                  {repetitiveInput(
                    "date",
                    "",
                    "Tanggal",
                    "",
                    (date) => selectedStartDate(date, idx, "start_date"),
                    "start_date",
                    elm.startDate
                  )}
                  <div className="d-flex gap-2">
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "From",
                        locationList,
                        (e) => handleChange(e, idx, "selectedLocation"),
                        "origin",
                        elm.origin
                      )}
                    </div>
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "To",
                        locationList,
                        (e) => handleChange(e, idx, "selectedDestination"),
                        "destination",
                        elm.destination
                      )}
                    </div>
                  </div>

                  {repetitiveInput(
                    "select",
                    "",
                    "Flight",
                    listFlight,
                    (e) => handleChange(e, idx, "flight"),
                    "object_id",
                    elm.object_id
                  )}
                  {elm.ticket_list?.length > 0 && (
                    <>
                      {repetitiveInput(
                        "select",
                        "",
                        "Pilih Seat",
                        elm?.ticketList,
                        (e) => handleChange(e, idx, "ticket_id"),
                        "ticket_id",
                        elm.ticket_id
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Harga",
                        "",
                        (e) => handleChange(e, idx, "price"),
                        "price",
                        elm.price,
                        true
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Total Harga",
                        "",
                        (e) => handleChange(e, idx, "price_flight"),
                        "total_price",
                        elm.total_price,
                        true
                      )}
                    </>
                  )}
                </div>
              )}
              {elm.object_model.includes("bus") && (
                <div>
                  {repetitiveInput(
                    "date",
                    "",
                    "Tanggal",
                    "",
                    (date) => selectedStartDate(date, idx, "start_date"),
                    "start_date",
                    elm.startDate
                  )}
                  <div className="d-flex gap-2">
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "From",
                        locationList,
                        (e) => handleChange(e, idx, "selectedLocation"),
                        "origin",
                        elm.origin
                      )}
                    </div>
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "To",
                        locationList,
                        (e) => handleChange(e, idx, "selectedDestination"),
                        "destination",
                        elm.destination
                      )}
                    </div>
                  </div>
                  {repetitiveInput(
                    "select",
                    "",
                    "Pilih Bus",
                    listBus,
                    (e) => handleChange(e, idx, "selectedBus"),
                    "object_id",
                    elm.object_id
                  )}
                  {elm.ticket_list?.length > 0 && (
                    <>
                      {repetitiveInput(
                        "select",
                        "",
                        "Pilih Seat",
                        elm?.ticketList,
                        (e) => handleChange(e, idx, "ticket_id"),
                        "ticket_id",
                        elm.ticket_id
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Total Harga",
                        "",
                        (e) => handleChange(e, idx, "price_bus"),
                        "total_price",
                        elm.total_price,
                        true
                      )}
                    </>
                  )}
                </div>
              )}
              {elm.object_model.includes("kereta") && (
                <div>
                  {repetitiveInput(
                    "date",
                    "",
                    "Tanggal",
                    "",
                    (date) => selectedStartDate(date, idx, "start_date"),
                    "start_date",
                    elm.startDate
                  )}
                  <div className="d-flex gap-2">
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "From",
                        locationList,
                        (e) => handleChange(e, idx, "selectedLocation"),
                        "origin",
                        elm.origin
                      )}
                    </div>
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "To",
                        locationList,
                        (e) => handleChange(e, idx, "selectedDestination"),
                        "destination",
                        elm.destination
                      )}
                    </div>
                  </div>
                  {repetitiveInput(
                    "select",
                    "",
                    "Train",
                    listTrain,
                    (e) => handleChange(e, idx, "selectedTrain"),
                    "object_id",
                    elm.object_id
                  )}
                  {elm.ticket_list?.length > 0 && (
                    <>
                      {repetitiveInput(
                        "select",
                        "",
                        "Pilih Seat",
                        elm?.ticketList,
                        (e) => handleChange(e, idx, "ticket_id"),
                        "ticket_id",
                        elm.ticket_id
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Total Harga",
                        "",
                        (e) => handleChange(e, idx, "price_bus"),
                        "total_price",
                        elm.total_price,
                        true
                      )}
                    </>
                  )}
                </div>
              )}
              {elm.object_model.includes("hotel") && (
                <div>
                  {repetitiveInput(
                    "date",
                    "",
                    "Check In",
                    "",
                    (date) => selectedStartDate(date, idx, "start_date"),
                    "start_date",
                    elm.startDate
                  )}

                  {repetitiveInput(
                    "date",
                    "",
                    "Check Out",
                    "",
                    (date) => selectedEndDate(date, idx, "end_date"),
                    "end_date",
                    elm.endDate
                  )}
                  {repetitiveInput(
                    "select",
                    "",
                    "Location",
                    locationList,

                    (e) => handleChange(e, idx, "selectedHotelLocation"),
                    "location",
                    elm.location,
                    ""
                  )}
                  {repetitiveInput(
                    "select",
                    "",
                    "Pilih Hotel",
                    listHotel,
                    (e) => handleChange(e, idx, "selectedHotel"),
                    "object_id",
                    elm.object_id
                  )}
                  {elm?.room_list?.length > 0 && (
                    <>
                      {repetitiveInput(
                        "select",
                        "",
                        "Pilih Room",
                        elm?.roomList,
                        (e) => handleChange(e, idx, "selected_room"),
                        "child_id",
                        elm.child_id
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Total Room",
                        "",
                        (e) => handleChange(e, idx, "hotel_room"),
                        "total_item",
                        elm.total_item
                      )}
                      {repetitiveInput(
                        "input",
                        "text",
                        "Total Harga",
                        "",
                        (e) => handleChange(e, idx, "total_price"),
                        "total_price",
                        elm.total_price,
                        true
                      )}
                    </>
                  )}
                  {elm?.room_list?.length > 0 && elm.object_id && (
                    <div>
                      {elm?.agenda?.map((_, i) => {
                        return (
                          <>
                            <div className="d-flex justify-content-between">
                              <div className={`mb-2 ${styles.titleCalculator}`}>
                                Agenda {i + 1}
                              </div>
                              <div onClick={(e) => deleteAgenda(e, i)}>
                                {/* <button
                                            className={styles.btnAction_reset}
                                            onClick={(e) => deleteAgenda(e, i)}
                                          > */}
                                <img src={trash} width={16} height={16} />
                                {/* </button> */}
                              </div>
                            </div>
                            {repetitiveInput(
                              "input",
                              "date",
                              "Tanggal Agenda",
                              "",
                              (e) =>
                                handleChangeAgenda(e, i, idx, "date_agenda"),
                              "tangal_agenda",
                              _.date_agenda
                            )}
                            <div className="d-flex gap-2">
                              <div className="w-50">
                                {repetitiveInput(
                                  "hour",
                                  "date",
                                  "Mulai",
                                  "",
                                  (e) =>
                                    handleChangeAgenda(e, i, idx, "start_time"),
                                  "start",
                                  _.start_time
                                )}
                              </div>
                              <div className="w-50">
                                {repetitiveInput(
                                  "hour",
                                  "date",
                                  "Selesai",
                                  "",
                                  (e) =>
                                    handleChangeAgenda(e, i, idx, "end_time"),
                                  "end",
                                  _.end_time
                                )}
                              </div>
                            </div>
                            {repetitiveInput(
                              "textarea",
                              "",
                              "Kegiatan",
                              "",
                              (e) =>
                                handleChangeAgenda(e, i, idx, "description"),
                              "description",
                              _.description
                            )}
                          </>
                        );
                      })}
                      <div className="w-100 mb-4">
                        {/* <div className={styles.titleCalculator}>
                                Agenda
                              </div> */}
                        <div>
                          <button
                            className={styles.btnAction}
                            onClick={(e) => addAgenda(e, idx)}
                          >
                            Add Agenda
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

const typeData = [
  {
    label: "Flight",
    value: "flight",
  },
  {
    label: "Hotel",
    value: "hotel",
  },
  {
    label: "Bus",
    value: "bus",
  },
  {
    label: "Train",
    value: "kereta",
  },
];
