import React from "react";
import { formatRupiah } from "../../constant/lib";

export default function PreviewTotal(props) {
  const { styles, handleSubmit, backtoInput, payload } = props;
  return (
    <div className={styles.stickyAction}>
      <div className={styles.previewTotal}>
        <div className={styles.text}>
          Total: 
          {payload.discount && (
            <>
              <span className={styles.subtotalText}>
                {formatRupiah(payload.sub_total)}
              </span>
              {"  "}
              <span className={styles.discountText}>{payload.discount}%</span>
            </>
          )}
        </div>
        <div className={styles.totalText}>
          {formatRupiah(payload.total)}
        </div>
      </div>
      <div className="d-flex gap-2">
        <button onClick={handleSubmit} className={`${styles.btnContinue}`} style={{marginTop: '12px'}}>
          Lanjutkan
        </button>
        <button
          className={`${styles.btnContinue} ${styles.btnContinue_outline}`}
          onClick={backtoInput}
        >
          Batal
        </button>
      </div>
    </div>
  );
}
