import { Link } from "react-router-dom";
import { formatRupiah, slugify } from "../../constant/lib";
import styles from "./CardProject.module.scss";
import defaultPlaceholder from "./../../assets/default.png";
import { Image } from "react-bootstrap";
import star from "./../../assets/Home/star.svg";

export default function CommonCard(props) {
  const { item, type, handleImageError } = props;
  return (
    <a
      href={`/${type == "hajj" || type == "umrah" ? "tour" : type}/${slugify(
        item?.title
      )}/${item?.id}`}
    >
      <div className={styles.thumbnail}>
        {item.image ? (
          <img alt="img" src={item.image} onError={handleImageError} />
        ) : (
          <img src={defaultPlaceholder} alt="default" />
        )}
        {item.discount_percent && (
          <div className={styles.discount}>{item.discount_percent}</div>
        )}
        {item.is_featured && <div className={styles.featured}>Rekomendasi</div>}
      </div>
      <div className={styles.detail}>
        <div className={styles.wrapTitle}>
          <div className={styles.title}>{item?.title}</div>
          {/* <div className={styles.count}>
            <Image src={user} alt="user" />
            <div>2</div>
          </div> */}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {
            <>
              {item.sale_price ? (
                item?.price ? (
                  <div className={styles.originalPrice}>
                    {formatRupiah(item?.price)}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {item.sale_price ? (
                <div className={styles.salePrice}>
                  {formatRupiah(item.sale_price)}
                </div>
              ) : (
                <div className="d-flex flex-column">
                  <div className={styles.startFrom}>Mulai Dari</div>
                  <div className={styles.salePrice}>
                    {formatRupiah(item?.price)}
                  </div>
                </div>
              )}
            </>
          }
          <div className={styles.summary}>
            <Image src={star} alt="rating" />
            <div className={styles.rate}>
              {item?.review_score?.score_total ??
                item?.review_score ??
                item?.star_rate}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
