import { Col, Container, Image, Row } from "react-bootstrap";
import WebLayout from "../components/Layout/WebLayout";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./../styles/Calculator.module.scss";
import arrowLeft from "./../assets/Profile/arrow-left.svg";
import { LocationServices } from "../http/LocationHttp";
import { PlaceServices } from "../http/PlaceHttp";
import moment from "moment";
import PreviewCalculator from "../components/Calculator/PreviewCalculator";
import { useNavigate } from "react-router-dom";
import ItineraryType from "../components/Calculator/ItineraryType";
import AddItinerary from "../components/Calculator/AddItinerary";
import PreviewTotal from "../components/Calculator/PreviewTotal";
import NonPreviewTotal from "../components/Calculator/NonPreviewTotal";
import { CalculatorServices } from "../http/CalculatorHttp";
import { BusesServices } from "../http/BusesHttp";
import { use } from "react";

export default function CalculatorWisata() {
  const history = useNavigate();

  const [showNav, setShowNav] = useState(false);
  const [selectedType, setSelectedType] = useState("date");
  const [selectedData, setSelectedData] = useState(null);
  const [priceTotal, setPriceTotal] = useState(0);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [listHotel, setListHotel] = useState(null);
  const [listFlight, setListFlight] = useState(null);
  const [listFlightFull, setListFlightFull] = useState(null);
  const [listTrain, setListTrain] = useState(null);
  const [listBus, setListBus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [itineraryType, setItineraryType] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [visa, setVisa] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [payload, setPayload] = useState(defaultPayload);
  const [isPreview, setIsPreview] = useState(false);
  const [rooms, setRooms] = useState(null);

  useEffect(() => {
    getLocation();
    getVisa("");
  }, []);

  // useEffect(() => {
  //   console.log(itineraryType);
  // }, [itineraryType]);

  let [currIdx, setCurrIdx] = useState(0);

  // useEffect(() => {
  //   let tmp = [...itineraryType];
  //   let data = tmp[currIdx];
  //   if (data?.room_list?.length > 0 && data?.child_id) {
  //     let findPrice = data.room_list.find((e) => e.id == data.child_id);
  //     data.total_price = findPrice.price;
  //     tmp[currIdx] = data;
  //     setItineraryType(tmp);
  //     console.log(tmp, findPrice, "data");

  //     // setItineraryType(tmp);
  //   }
  //   // tmp[currIdx] = data;
  // }, [currIdx, rooms]);

  useEffect(() => {
    // let tmpPayload = { ...payload };
    // tmpPayload.sub_total = subTotal;
    // setPayload(tmpPayload);
    console.log(subTotal, "subTotal");
    
  }, [subTotal]);

  useEffect(() => {
    if (payload.discount) {
      let discount = payload.discount;
      let tmpTotalPrice = subTotal - (subTotal * discount) / 100;
      setTotalPrice(tmpTotalPrice);
    }
  }, [subTotal, payload]);

  useEffect(() => {
    let tmp = { ...payload };
    tmp.total = totalPrice;
    setPayload(tmp);
  }, [totalPrice]);

  useEffect(() => {
    let tmp = { ...payload };
    tmp.total = totalPrice;
    setPayload(tmp);
  }, [totalPrice]);

  useEffect(() => {
    if (payload.guests) {
      let tmp = [...itineraryType];
      let subTotalData = 0;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].object_model == "hotel") {
          tmp[i].total_price = tmp[i].price * parseInt(tmp[i].total_item);
          subTotalData += tmp[i].price * parseInt(tmp[i].total_item);
        } else {
          tmp[i].total_price =
            tmp[i].price * (payload.guests ? payload.guests : 1);
          subTotalData += tmp[i].price * (payload.guests ? payload.guests : 1);
        }
        // console.log(subTotalData, tmp[i], "subTotal");
        setSubTotal(subTotalData);
      }

      setItineraryType(tmp);
    }
  }, [payload.guests]);

  useEffect(() => {
    if (itineraryType?.length > 0) {
      let tmp = [...itineraryType];
      let subTotalData = 0;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].object_model == "hotel") {
          tmp[i].total_price = tmp[i].price * parseInt(tmp[i].total_item);
          subTotalData += tmp[i].price * parseInt(tmp[i].total_item);
        } else {
          tmp[i].total_price =
            tmp[i].price * (payload.guests ? payload.guests : 1);
          subTotalData += tmp[i].price * (payload.guests ? payload.guests : 1);
        }
        // console.log(tmp[i], "tmp");

        setSubTotal(subTotalData);
      }
      // setSubTotal(subTotalData);
    }
  }, [itineraryType]);

  useEffect(() => {
    // let tmp = [...itineraryType];
    // let subTotal = 0;
    // for (let i = 0; i < tmp.length; i++) {
    //   subTotal += tmp[i].total_price;
    // }
    // payload.sub_total = subTotal;
    // setPayload(payload);

    let tmp = { ...payload };
    tmp.sub_total = subTotal;
    tmp.total = subTotal;
    setPayload(tmp);
  }, [subTotal]);

  useEffect(() => {
    console.log(subTotal, "subTotal");
  }, [subTotal]);

  // useEffect(() => {
  //   console.log(itineraryType, "itineraryType", payload, "payload");
  // }, [itineraryType, payload]);

  const handleChange = (e, id, type) => {
    const { value, name } = e.target;
    let selectedValue =
      e.target.selectedOptions &&
      e.target.selectedOptions[0].attributes[1].value;

    setCurrIdx(id);
    // let splitData = value.split("-");
    let newData = [...itineraryType];
    newData[id] = {
      ...newData[id],
      [name]: value,
    };
    if (type) {
      newData[id] = { ...newData[id], [type]: selectedValue };
    }

    setItineraryType(newData);
    if (type === "selectedHotel") {
      let params = `?start_date=${
        startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      }&end_date=${
        endDate
          ? moment(endDate).format("YYYY-MM-DD")
          : moment().add(1, "days").format("YYYY-MM-DD")
      }&adults=1`;
      getDetailListData(value, params, id, type, selectedValue);
    } else if (type === "selected_room") {
      let tmp = [...itineraryType];
      let tmpSubTotal = { ...subTotal };
      let data = tmp;
      data[id].child_id = value;
      if (data[id]?.room_list?.length > 0 && data[id]?.child_id) {
        let findPrice = data[id]?.room_list.find(
          (e) => e.id == data[id]?.child_id
        );
        data[id].price = findPrice.price;
        data[id].total_price = findPrice.price;
        tmpSubTotal = parseInt(data[id].price);
        // data[id].total_price =
        //   parseInt(findPrice.price) * (payload.guests ? payload.guests : 1);
        // let tmpSubTotal = subTotal;
        // tmpSubTotal += parseInt(data[id].total_price);
        setSubTotal(tmpSubTotal);

        setItineraryType(tmp);
      }
    } else if (type === "hotel_room") {
      let tmp = [...itineraryType];
      let tmpSubTotal = { ...subTotal };
      let data = tmp;
      data[id].total_item = value;
      data[id].total_price = value * parseInt(data[id].price);
      tmpSubTotal = value * parseInt(data[id].price);
      // data[id].total_price = value * data[id].total_price;
      // let tmpSubTotal = subTotal;
      // tmpSubTotal += parseInt(data[id].total_price);
      setSubTotal(tmpSubTotal);
      setItineraryType(tmp);
    } else if (type === "flight") {
      getDetailFlight(value, id, type, selectedValue);
    } else if (type === "ticket_id") {
      let tmp = [...itineraryType];
      // let tmpSubTotal = subTotal;
      let data = tmp;
      data[id].ticket_id = value;
      if (data[id]?.ticket_list?.length > 0 && data[id]?.ticket_id) {
        let findPrice = data[id]?.ticket_list.find(
          (e) => e.id == data[id]?.ticket_id
        );
        data[id].price = parseInt(findPrice.price);
        data[id].total_price =
          data[id].price * (payload.guests ? payload.guests : 1);
        data[id].total_item = payload.guests ? payload.guests : 1;
        // tmpSubTotal += parseInt(data[id].total_price);
        // setSubTotal(tmpSubTotal);
      }
      setItineraryType(tmp);
    } else if (type === "selectedBus") {
      getDetailBus(value, id, type, selectedValue);
    } else if (type === "selectedTrain") {
      getDetailTrain(value, id, type, selectedValue);
    } else {
      getListData(value);
    }
  };

  const handleChangeCalculator = (e) => {
    const { value, name } = e.target;
    const newData = { ...payload };
    newData[name] = value;
    setPayload(newData);
  };

  const selectHotel = (e) => {
    const { name, value } = e.target;
    setSelectedHotel(value);
  };

  const addAgenda = (e, idx) => {
    e.preventDefault();
    let tmp = [...itineraryType];
    if (!tmp[idx].agenda) {
      tmp[idx].agenda = []; // Initialize agenda if it doesn't exist
    }
    tmp[idx].agenda = [...tmp[idx].agenda, defaultAgenda];
    setItineraryType(tmp);
  };

  const addItinerary = (e) => {
    e.preventDefault();
    setItineraryType([...itineraryType, defaultItinerary]);
  };

  const handleChangeAgenda = (e, i, idx, name) => {
    let tmp = [...itineraryType];
    let dt = null;
    if (name !== "description") dt = moment(e).format("DD-MM-YYYY HH:mm");

    tmp[idx].agenda[i] = {
      ...tmp[idx].agenda[i],
      [name]:
        name === "description" || name === "date_agenda" ? e.target.value : e,
    };
    setItineraryType(tmp);
  };

  const deleteAgenda = (e, i) => {
    e.preventDefault();
    const newData = [...itineraryType];
    newData.splice(i, 1);
    setAgenda(newData);
  };

  const deleteAkomodasi = (e, i) => {
    e.preventDefault();
    const newData = [...itineraryType];
    newData.splice(i, 1);
    setItineraryType(newData);
  };

  const getVisa = async (param = "") => {
    try {
      let response = await PlaceServices.visaList(param);
      let tmp = [];
      response.data.map((e, i) => {
        tmp[i] = {}; // Initialize the object at index i
        tmp[i].label = `${e.title}`;
        tmp[i].value = e.id;
      });
      setVisa(tmp);
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailListData = async (id, params, idx, type, selectedValue) => {
    try {
      let response = await PlaceServices.checkAvailability(id, params);
      if (response) {
        let tmp = [];
        let roomsData = response.rooms;
        for (let i = 0; i < roomsData?.length; i++) {
          tmp.push({
            label: `${roomsData[i].title}`,
            value: roomsData[i].id,
          });
        }
        let clone = [...itineraryType];
        clone[idx].room_list = roomsData;
        clone[idx].roomList = tmp;
        clone[idx].object_id = id;
        clone[idx][type] = selectedValue;
        setItineraryType(clone);

        setRooms(tmp);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailBus = async (id, idx, type, selectedValue) => {
    try {
      let response = await BusesServices.getDetailBusUmroh(id);
      if (response) {
        let tmp = [];
        let seatData = response.data.bus_seat;
        for (let i = 0; i < seatData?.length; i++) {
          tmp.push({
            label: `${seatData[i].seat_type.code}`,
            value: seatData[i].id,
          });
        }
        let clone = [...itineraryType];
        clone[idx].object_id = id;
        clone[idx].ticket_list = seatData;
        clone[idx].ticketList = tmp;
        clone[idx][type] = selectedValue;
        setItineraryType(clone);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailTrain = async (id, idx, type, selectedValue) => {
    try {
      let response = await PlaceServices.trainById(id);
      if (response) {
        let tmp = [];
        let seatData = response.data.bus_seat;
        for (let i = 0; i < seatData?.length; i++) {
          tmp.push({
            label: `${seatData[i].seat_type.code}`,
            value: seatData[i].id,
          });
        }
        let clone = [...itineraryType];
        clone[idx].object_id = id;
        clone[idx].ticket_list = seatData;
        clone[idx].ticketList = tmp;
        clone[idx][type] = selectedValue;
        setItineraryType(clone);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailFlight = async (id, idx, type, selectedValue) => {
    try {
      let response = await PlaceServices.flightById(id);
      if (response.status == 1) {
        let tmp = [];
        let seatData = response.data.flight_seat;
        for (let i = 0; i < seatData?.length; i++) {
          tmp.push({
            label: `${seatData[i].seat_type.code}`,
            value: seatData[i].id,
          });
        }
        let clone = [...itineraryType];
        clone[idx].object_id = id;
        clone[idx].ticket_list = seatData;
        clone[idx].ticketList = tmp;
        clone[idx][type] = selectedValue;
        setItineraryType(clone);
        // setListFlight(tmp);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListData = async (data) => {
    try {
      let response = await PlaceServices.placeList(data, `?limit=4`, {});
      if (response) {
        if (data === "hotel") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {
              label: `${e.title}`,
              value: e.id,
            };
          });
          setListHotel(tmp);
        } else if (data === "bus") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title} - ${e.code}`;
            tmp[i].value = e.id;
          });
          setListBus(tmp);
        } else if (data === "flight") {
          let tmp = [];
          response.message.map((e, i) => {
            tmp.push({
              label: `${e.title} - ${e.code}`,
              value: e.id,
            });
          });
          setListFlightFull(response.message);
          setListFlight(tmp);
        } else {
          let tmp = [];
          response.message.map((e, i) => {
            tmp[i] = {}; // Initialize the object at index i
            tmp[i].label = `${e.title}`;
            tmp[i].value = e.id;
          });
          setListTrain(tmp);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectedStartDate = (date, idx, name) => {
    setStartDate(date);
    let dt = date;
    dt = moment(dt).format("DD-MM-YYYY HH:mm");

    const newData = [...itineraryType];
    newData[idx] = { ...newData[idx], startDate: date, [name]: dt };
    setItineraryType(newData);
  };

  const selectedEndDate = (date, idx, name) => {
    setEndDate(date);
    let dt = date;
    dt = moment(dt).format("DD-MM-YYYY HH:mm");
    const newData = [...itineraryType];
    newData[idx] = { ...newData[idx], endDate: date, [name]: dt };
    setItineraryType(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...payload };
    data.itinerary = itineraryType;
    setPayload(data);
    setIsPreview(true);
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    try {
      const response = await CalculatorServices.postCalculator(payload);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getLocation = async () => {
    // setIsLoading(true);
    try {
      const response = await LocationServices.locationList();
      if (response) {
        let tmp = [];
        response.data.map((e, i) => {
          tmp[i] = {}; // Initialize the object at index i
          tmp[i].label = `${e.name}`;
          tmp[i].value = e.id;
        });
        setLocationList(tmp);
      }
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  const handleBack = () => {
    setIsPreview(false);
    history("/");
  };

  const backtoInput = () => {
    setIsPreview(false);
  };

  return (
    <WebLayout showNav={showNav}>
      <Container>
        <Row className="mb-5 justify-content-center">
          {isPreview ? (
            <>
              <PreviewCalculator payload={payload} handleBack={handleBack} />
              <PreviewTotal
                payload={payload}
                styles={styles}
                handleSubmit={handleSubmitData}
                backtoInput={backtoInput}
              />
            </>
          ) : (
            <Col xs={12} lg={10}>
              <div className="mb-4 mt-4">
                <div className={`d-flex flex-column mb-2`}>
                  <Image
                    onClick={handleBack}
                    className="mb-2"
                    src={arrowLeft}
                    alt="arrow-left"
                    width={24}
                  />
                  <h1 className="mb-2 mb-md-3">Kalkulator Wisata</h1>
                </div>
                <form>
                  {repetitiveInput(
                    "input",
                    "text",
                    "Judul Wisata",
                    "",
                    handleChangeCalculator,
                    "title",
                    payload.title
                  )}
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="w-50">
                      {repetitiveInput(
                        "select",
                        "",
                        "Kategori",
                        category,
                        handleChangeCalculator,
                        "category",
                        payload.category
                      )}
                    </div>
                    <div className="w-50">
                      {repetitiveInput(
                        "input",
                        "text",
                        "Jumlah Peserta",
                        "",
                        handleChangeCalculator,
                        "guests",
                        payload.guests
                      )}
                    </div>
                  </div>
                  {repetitiveInput(
                    "select",
                    "",
                    "Visa",
                    visa,
                    handleChangeCalculator,
                    "visa",
                    payload.visa
                  )}
                  {repetitiveInput(
                    "select",
                    "",
                    "Tour Guide",
                    visa,
                    handleChangeCalculator,
                    "tour_guide",
                    payload.tour_guide
                  )}
                </form>
              </div>
              <div>
                <form className="mb-5">
                  <ItineraryType
                    itineraryType={itineraryType}
                    styles={styles}
                    repetitiveInput={repetitiveInput}
                    handleChange={handleChange}
                    deleteAkomodasi={deleteAkomodasi}
                    deleteAgenda={deleteAgenda}
                    addAgenda={addAgenda}
                    handleChangeAgenda={handleChangeAgenda}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    handleSubmit={handleSubmit}
                    addItinerary={addItinerary}
                    locationList={locationList}
                    listFlight={listFlight}
                    listBus={listBus}
                    listTrain={listTrain}
                    listHotel={listHotel}
                    rooms={rooms}
                    selectedHotel={selectedHotel}
                    payload={payload}
                  />

                  <AddItinerary addItinerary={addItinerary} styles={styles} />
                  <div className="mt-3">
                    {repetitiveInput(
                      "input",
                      "text",
                      "Sub Total",
                      "",
                      handleChangeCalculator,
                      "sub_total",
                      payload.sub_total,
                      true
                    )}
                    {repetitiveInput(
                      "input",
                      "text",
                      "Diskon",
                      "",
                      handleChangeCalculator,
                      "discount",
                      payload.discount
                    )}
                    {repetitiveInput(
                      "input",
                      "text",
                      "Total",
                      "",
                      handleChangeCalculator,
                      "total",
                      payload.total,
                      true
                    )}
                  </div>
                  {/* {selectedData && ( */}
                  <NonPreviewTotal
                    styles={styles}
                    handleSubmit={handleSubmit}
                    payload={payload}
                  />
                  {/* )} */}
                </form>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </WebLayout>
  );
}

const defaultData = [
  {
    label: "Option 1",
    value: "option_1",
  },
];

const defaultAgenda = {
  date_agenda: "",
  start_time: "",
  end_time: "",
  description: "",
};

const category = [
  {
    label: "City Trips",
    value: "1",
  },
  {
    label: "Ecotourism",
    value: "2",
  },
  {
    label: "Escorted Tour",
    value: "3",
  },
  {
    label: "Lingula",
    value: "4",
  },
  {
    label: "Haji",
    value: "5",
  },
  {
    label: "Tour",
    value: "6",
  },
];

const defaultPayload = {
  title: "",
  category: "",
  guests: "",
  visa: "",
  tour_guide: "",
  itinerary: [],
  sub_total: "",
  discount: "",
  total: "",
};

const defaultItinerary = {
  object_model: "",
  start_date: "",
  end_date: "",
  location_id: "",
  origin: "",
  destination: "",
  object_id: "",
  child_id: "",
  ticket_id: "",
  total_item: "",
  total_price: "",
  agenda: [],
  sequence_number: "",
  price: "",
  total_guests: "",
  discount: "",
  ticket_list: [],
  room_list: [],
  total_room: "",
  seat_type: "",
};

const repetitiveInput = (type, inputType, label, data, func, name, value, isDisabled) => {
  switch (type) {
    case "input":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <input
            type={inputType}
            name={name}
            onChange={func}
            value={value}
            placeholder={`Masukkan ${label}`}
            disabled={isDisabled ? isDisabled : false}
          />
        </div>
      );
    case "select":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <select onChange={func} name={name} value={value} disabled={isDisabled ? isDisabled : false}>
            <option value="" hidden>
              Pilih {label}
            </option>
            {data ? (
              data?.length > 0 ? (
                data?.map((_, i) => (
                  <option value={`${_.value}`} name={_.label}>
                    {_.label}
                  </option>
                ))
              ) : (
                <option value="">Tidak Ada Data</option>
              )
            ) : (
              <option value="">Memuat Data...</option>
            )}
          </select>
        </div>
      );
    case "textarea":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <textarea rows={3} name={name} onChange={func} disabled={isDisabled ? isDisabled : false}>
            {value}
          </textarea>
        </div>
      );
    case "date":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <DatePicker
            selected={value}
            onChange={func}
            timeIntervals={30}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd MMMM yyyy, HH:mm"
            placeholderText={`Pilih Waktu ${label}`}
          />
        </div>
      );
    case "hour":
      return (
        <div className="form-group d-flex flex-column mb-3">
          <label className="mb-2">{label}</label>
          <DatePicker
            selected={value}
            onChange={func}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            placeholderText={`Waktu ${label}`}
          />
          {/* <DatePicker
            selected={value}
            onChange={func}
            timeIntervals={30}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd MMMM yyyy, HH:mm"
            placeholderText={`Pilih Waktu ${label}`}
          /> */}
        </div>
      );
    default:
      return null;
  }
};
