import { riyuzAPIV2, riyuzAPI } from "../constant/api";

export const BusesServices = {
  getBuses(id, param) {
    return id
      ? riyuzAPIV2.get(`umroh-buses/${id}`)
      : riyuzAPIV2.get(`umroh-buses${param ? param : ""}`);
  },
  postBuses(payload) {
    return riyuzAPIV2.post(`umroh-buses`, payload);
  },
  getDetailBusUmroh(id) {
    return riyuzAPI.get(`bus/detail/${id}`);
  },
  putBuses(payload) {
    return riyuzAPIV2.put(`umroh-buses/${payload.id}`, payload.body);
  },
  deleteBuses(id) {
    return riyuzAPIV2.delete(`umroh-buses/${id}`);
  },
};
