import { Col, Image } from "react-bootstrap";
import bus from "./../../assets/typeAccomodation/bus.svg";
import flight from "./../../assets/typeAccomodation/flight.svg";
import hotel from "./../../assets/typeAccomodation/hotel.svg";
import train from "./../../assets/typeAccomodation/train.svg";
import location from "./../../assets/location.svg";
import styles from "./../../styles/PreviewCalculator.module.scss";
import arrowLeft from "./../../assets/Profile/arrow-left.svg";
import Loading from "../common/Loading";
import moment from "moment";

export default function PreviewCalculator(props) {
  const { payload, handleBack } = props;
  let itinerary = payload.itinerary.sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date)
  );

  const iconGenerator = (type) => {
    let tmp = type.split("-");
    tmp = tmp[0];
    switch (tmp) {
      case "bus":
        return (
          <div className={`${styles.icon} ${styles.icon_bus}`}>
            <img src={bus} width={20} height={20} />
          </div>
        );
      case "flight":
        return (
          <div className={`${styles.icon} ${styles.icon_flight}`}>
            <img src={flight} width={20} height={20} />
          </div>
        );
      case "hotel":
        return (
          <div className={`${styles.icon} ${styles.icon_hotel}`}>
            <img src={hotel} width={20} height={20} />
          </div>
        );
      case "kereta":
        return (
          <div className={`${styles.icon} ${styles.icon_train}`}>
            <img src={train} width={20} height={20} />
          </div>
        );
      default:
        return <></>;
    }
  };
  const cardItineraryGenerator = (data) => {
    let tmp = data.object_model;

    switch (tmp) {
      case "bus":
      case "flight":
      case "kereta":
        return (
          <div className={styles.itemItinerary}>
            <div className={styles.iconWrapItinerary}>
              {iconGenerator(data.object_model)}
            </div>
            <div className={styles.dateRangeWrap}>
              <div className={styles.dateRangeItinerary}>
                <div className={styles.date}>
                  {data.start_date}
                </div>
              </div>
              <div className={styles.typeAccomodation}>
                {data.accomodation} -{" "}
                {data.selectedBus
                  ? data.selectedBus
                  : data.flight
                  ? data.flight
                  : data.selectedTrain
                  ? data.selectedTrain
                  : ""}
              </div>
              <div className={styles.wrapDestination}>
                <div className={styles.destination}>
                  <div className={styles.label}>Dari</div>
                  <span className="me-1">
                    <img src={location} width={12} height={12} />
                  </span>
                  {data.selectedLocation}
                </div>
                <div className={styles.destination}>
                  <div className={styles.label}>Ke</div>
                  <span className="me-1">
                    <img src={location} width={12} height={12} />
                  </span>
                  {data.selectedDestination}
                </div>
              </div>
            </div>
          </div>
        );
      case "hotel":
        return (
          <div className={styles.itemItinerary}>
            <div className={styles.iconWrapItinerary}>
              {iconGenerator(data.object_model)}
            </div>
            <div className={styles.dateRangeWrap}>
              <div className={styles.dateRangeItinerary}>
                <div className={styles.date}>
                  {data.start_date} -{" "}
                  {data.end_date}
                </div>
              </div>
              <div className={`${styles.typeAccomodation} mb-2`}>
                {data.accomodation} - {data.selectedHotel}
              </div>
              {data.agenda?.length > 0 &&
                data.agenda.map((elm, idx) => {
                  return (
                    <div className={styles.cardAgenda}>
                      <div className={styles.titleAgenda}>Agenda {idx + 1}</div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className={styles.subTitle}>Waktu Mulai</div>
                          <div className={styles.dateText}>
                            {moment(elm.start_time).format("DD/MM/YYYY HH:mm")}
                              
                          </div>
                        </div>
                        <div>
                          <div className={styles.subTitle}>Waktu Selesai</div>
                          <div className={styles.dateText}>
                            {moment(elm.end_time).format("DD/MM/YYYY HH:mm")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.subTitle}>Deskripsi</div>

                      <div className={styles.dateText}>{elm.description}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <Col lg={12}>
      <div className="mb-4">
        <div className={`d-flex flex-column mb-2`}>
          <Image
            onClick={handleBack}
            className="mb-2"
            src={arrowLeft}
            alt="arrow-left"
            width={24}
          />
          <h1 className="mb-2 mb-md-3">Preview Kalkulator Wisata</h1>
        </div>
        <div>
          <label className={`${styles.labelCustom}`}>Judul Wisata</label>
          <div className={styles.textPreview}>{payload.title}</div>
        </div>

        <div className="d-flex justify-content-between gap-2">
          <div className="w-50">
            <label className={`${styles.labelCustom}`}>Kategori</label>
            <div className={styles.textPreview}>
              {payload.category.split("-")[1]}
            </div>
          </div>
          <div className="w-50">
            <label className={`${styles.labelCustom}`}>Jumlah Peserta</label>
            <div className={styles.textPreview}>{payload.guests}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <div className="w-50">
            <label className={`${styles.labelCustom}`}>Visa</label>
            <div className={styles.textPreview}>{payload.visa}</div>
          </div>
          <div className="w-50">
            <label className={`${styles.labelCustom}`}>Tour Guide</label>
            <div className={styles.textPreview}>{payload.tourGuide}</div>
          </div>
        </div>
        <div>
          <label className="mb-2">Itinerary</label>
          <div>
            {itinerary?.length > 0
              ? itinerary?.map((_, i) => {
                  return cardItineraryGenerator(_);
                })
              : "-"}
          </div>
        </div>
      </div>
    </Col>
  );
}
